<template>
  <el-dialog
    class="student-only-read-dialog"
    title="查看学生信息"
    :visible.sync="show"
    width="750px"
    :before-close="close">
    <el-divider content-position="left">个人信息</el-divider>
    <el-descriptions :column="2" border>
      <el-descriptions-item label-class-name="descriptions-item__label" label="用户照片">
        <el-image v-if="formData.stuExamImg" :src="`${$fileUrl}${formData.stuExamImg}`" width="200px" />
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="姓名">
        {{ formData.stuName }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="学号">
        {{ formData.stuNo }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="录取编号">
        {{ formData.examineNo }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="性别">
        {{ formData.sexType === 1 ? '男' : formData.sexType === 2 ? '女' : '' }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="民族">
        {{ formData.nation }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="政治面貌">
        {{ formData.political }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="身份证号">
        {{ formData.idNumber }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="联系电话">
        {{ formData.fixedPhone }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="中学名称">
        {{ formData.middleSchool }}
      </el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">专业信息</el-divider>
    <el-descriptions :column="2" border>
      <el-descriptions-item label-class-name="descriptions-item__label" label="年级">
        {{ formData.grade }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="班级">
        <!-- showSubDialog的title属性没有上,借用来传递专业信息  -->
        {{ title.className || '未分配' }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="专业">
        <!-- showSubDialog的title属性没有上,借用来传递专业信息  -->
        {{ title.majorName }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="院系">
        <!-- showSubDialog的title属性没有上,借用来传递专业信息  -->
        {{ title.collegeName }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="职务">
        {{ formData.job }}
      </el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">联系信息</el-divider>
    <el-descriptions :column="3" border>
      <el-descriptions-item label-class-name="descriptions-item__label" label="联系人">
        {{ formData.linkMan }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="手机号码">
        {{ formData.linkManPhone }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="父亲姓名">
        {{ formData.fatherName }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="父亲电话">
        {{ formData.fatherPhone }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="母亲姓名">
        {{ formData.motherName }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="母亲电话">
        {{ formData.motherPhone }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="邮编">
        {{ formData.postCode }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="详细地址">
        {{ formData.addressDetails }}
      </el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">录取信息</el-divider>
    <el-descriptions :column="2" border>
      <el-descriptions-item label-class-name="descriptions-item__label" label="考生号">
        {{ formData.examineCode }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="毕业类别">
        {{ formData.graduateCategory }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="录取阶段">
        {{ formData.admissionStage }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="录取批次">
        {{ formData.admissionBatch }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="计划类别">
        {{ formData.categoryName }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="录取类别">
        {{ formData.admissionCategory }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="录取总分">
        {{ formData.admissionMark }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="科类名称">
        {{ formData.subjectKind }}
      </el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">专升本意向</el-divider>
    <el-descriptions :column="2" border>
      <el-descriptions-item label-class-name="descriptions-item__label" label="升本意向学校">
        {{ formData.bachelorIntentionSchool }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="意向专业">
        {{ formData.bachelorIntentionMajor }}
      </el-descriptions-item>
    </el-descriptions>
    <template #footer>
      <el-button @click="close">关 闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { getStudentById } from '@/api/student'
import dialog from '@/vue/mixins/dialog'
import campusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'

export default {
  name: 'studentOnlyReadDialog',
  mixins: [dialog, campusSchMajorClasses],
  data () {
    return {
      formData: {
        id: '',
        // 学生个人信息
        stuExamImg: '', // 用户照片
        userId: '', // 用户id
        stuNo: '', // 学号
        stuName: '', // 学生姓名
        examineNo: '', // 录取编号
        sexType: 1, // 性别（1——男，2——女）
        nation: '', // 民族
        political: '', // 政治面貌
        idNumber: '', // 身份证号
        fixedPhone: '', // 手机号码
        birthday: '', // 出生年月日
        userType: 2, // 用户类型1教职工,2学生,3后台管理员
        stuStatus: 0, // 学生状态（0——未注册，1——在读，2——毕业）
        middleSchool: '', // 中学名称
        // campusId: '', // 校区id
        // 录取信息
        examineCode: '', // 考生号
        graduateCategory: '', // 毕业类别
        admissionStage: '', // 录取阶段
        admissionBatch: '', // 录取批次
        categoryName: '', // 计划类别名称
        admissionCategory: '', // 录取类别
        admissionMark: '', // 录取总分
        subjectKind: '', // 科类名称
        // 专业信息
        // campusId: '', // 校区
        collegeId: '', // 学院
        grade: '', // 年级
        majorId: '', // 专业
        classesId: '', // 班级
        job: '', // 职务
        // 专升本意向信息
        bachelorIntentionSchool: '', // 专升本意向学校
        bachelorIntentionMajor: '', // 专升本意向专业
        // 联系信息
        linkMan: '', // 联系人
        linkManPhone: '', // 联系电话
        fatherName: '', // 父亲姓名
        fatherPhone: '', // 父亲电话
        motherName: '', // 母亲姓名
        motherPhone: '', // 母亲电话
        postCode: '', // 邮票
        addressDetails: '', // 详细地址
        remarks: '' // 备注
      }
    }
  },
  async mounted () {
    await Promise.all([
      this.getCollegeList(),
      this.getMajorList()
    ])
    await this.$nextTick()
    if (this.id) {
      await this.getDataById(this.id, getStudentById)
      // await this.getClassList({
      //   grade: this.formData.grade,
      //   majorId: this.formData.majorId
      // })
    }
  },
  methods: {
    // 根据专业Id获取学院信息
    getMajorById (majorId) {
      this.$http.getMajorById(majorId).then((res) => {
        // this.formData.campusId = res.data.campusId
        this.formData.collegeId = res.data.collegeId
      })
    },
    // 选择年级
    changeGrade (val) {
      this.formData.classesId = ''
      this.getClassList({
        grade: this.formData.grade,
        majorId: this.formData.majorId
      })
      this.getMajorList({ grade: val })
    },
    // 选择专业
    changeMajor (majorId) {
      this.formData.collegeId = ''
      // this.formData.campusId = ''
      this.formData.classesId = ''
      this.getMajorById(majorId)
      this.getClassList({
        grade: this.formData.grade,
        majorId: majorId
      })
    }
  }
}
</script>
<style lang="scss">
.student-only-read-dialog {
  .el-dialog {
    margin-top: 20px !important;

    .el-dialog__body {
      padding-top: 0;
    }
  }
}
</style>
