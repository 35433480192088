<template>
  <el-dialog
    class="dialog-export-stu-select"
    title="导出学生名单-表头筛选"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="close"
    width="720px"
  >
    <el-card class="mb-1" shadow="never">
      <div>
        <el-checkbox v-model="checkAll" @change="handleCheckAll">全选</el-checkbox>
      </div>
      <div class="">
        <el-checkbox-group class="grid" v-model="includeFieldNames">
          <el-checkbox v-for="{value,label} in keyList" :key="value" :label="value">{{ label }}</el-checkbox>
        </el-checkbox-group>
      </div>
    </el-card>
    <template #footer>
      <el-button @click="close">关 闭</el-button>
      <el-button type="primary" @click="exportExcel">开始导出</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'

export default {
  name: 'DialogExportStuSelect',
  mixins: [dialog],
  props: {
    queryInfo: {
      type: Object,
      default: () => ({}) // 加括号确保每次都是新的对象实例
    }
  },
  data() {
    return {
      checkAll: false,
      includeFieldNames: [],
      keyList: []
    }
  },
  created() {
    this.keyList = Object.entries(this.$store.state.systemParam.exportStuKeys).map(item => {
      return {
        value: item[0],
        label: item[1]
      }
    })
    const _queryMap = {
      collegeId: 'collegeName',
      majorId: 'majorName',
      classId: 'className',
      grade: 'grade'
    }
    const _queryInfo = []
    Object.entries(this.queryInfo).forEach(([valueName, value]) => {
      if ((value || value === 0) && _queryMap[valueName]) {
        _queryInfo.push(_queryMap[valueName])
      }
    })
    const _includeFieldNames = window.localStorage.getItem(this.$route.name + ':exportStuKeys') || '["stuName", "stuNo", "majorName", "className"]'
    this.includeFieldNames = [...new Set([..._queryInfo, ...JSON.parse(_includeFieldNames)])]
  },
  mounted() {
  },
  methods: {
    // 全选
    handleCheckAll(val) {
      if (val) {
        this.includeFieldNames = this.keyList.map(item => item.value)
      } else {
        this.includeFieldNames = []
      }
    },
    // 导出
    async exportExcel() {
      window.localStorage.setItem(this.$route.name + ':exportStuKeys', JSON.stringify(this.includeFieldNames))

      // 导出已缴费名单
      let stuStatusName = ''
      if (!['', null].includes(this.queryInfo.stuStatus) && this?.$store?.state?.systemParam?.stuStatus?.length) {
        this.$store.state.systemParam.stuStatus.forEach(item => {
          if (+item.paramValue === +this.queryInfo.stuStatus) {
            stuStatusName = item.paramName
          }
        })
      }
      this.$http.exportExcelHttp.exportStudentInfo({
        ...this.queryInfo,
        includeFieldNames: this.includeFieldNames
      }, stuStatusName + '学生名单').then(() => {
        this.$message.success('导出成功，开始下载。')
        this.close()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-export-stu-select {
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .el-checkbox {
      margin-top: 1em;
    }
  }
}
</style>
